@use './colors' as colors;

@media (min-width: 1500px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1400px;
  }
}

.flex-row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-flow-wrap {
  flex-flow: wrap;
}

.flex-break {
  flex-basis: 100%;
  height: 0;
}

.content-center {
  display: flex;

  flex-wrap: wrap;

  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.vertical-align-top {
  vertical-align: top;
}

.vertical-align-middle {
  vertical-align: middle !important;
}

.cursor-pointer {
  cursor: pointer;
}

.left-menu {
  @extend .left-menu-background;

  width: 300px;
  word-break: break-all;
}

.hover-show-container {
  .hover-show-target {
    display: none;
  }

  &:hover {
    .hover-show-target {
      display: inline-block;
    }
  }
}

.hover-visible-container {
  .hover-visible-target {
    visibility: hidden;
  }

  &:hover {
    .hover-visible-target {
      visibility: visible;
    }
  }
}

@mixin hover-fade {
  cursor: pointer;
  transition: opacity 0.25s ease-in-out;

  user-select: none;

  &:hover {
    opacity: 0.8;
  }
}

.hover-fade {
  @include hover-fade;
}

.hover-strong-container {
  &:hover {
    .hover-strong {
      opacity: 1;
    }
  }
}

.hover-strong {
  cursor: pointer;
  transition: opacity 0.25s ease-in-out;

  opacity: 0.6;

  user-select: none;

  &:hover {
    opacity: 1;
  }
}

.hover-highlight {
  &:hover {
    background-color: colors.$highlighted-background-color;
  }
}

.hover-grow {
  cursor: pointer;
  transition: transform 0.25s ease-in-out;

  user-select: none;

  &:hover {
    transform: scale(1.05);
  }
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

.text-big {
  font-size: 105%;
}

.text-medium {
  font-size: 90%;
}

.text-small {
  font-size: 70%;
}

.text-weak {
  opacity: 0.95;
}

@for $i from 0 through 5 {
  .z-index-#{ $i } {
    position: relative;
    z-index: $i;
  }
}

.px-45 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.foreground-faded {
  position: relative;

  &::before {
    position: absolute;

    display: block;

    width: 100%;
    height: 100%;

    background-color: #fff;
    opacity: 0.5;

    content: '';
  }
}

.opacity-5 {
  opacity: 0.5;
}

.overflow-auto-y {
  overflow-y: auto;
}
