@use './colors' as colors;

#page-container {
  & > * {
    width: 100%;
  }

  & > router-outlet {
    width: 0 !important;
  }
}

* {
  box-sizing: border-box;
}

html, body {
  font-family: 'Inter', sans-serif;
  font-weight: 400;

  height: 100%;
  padding: 0;
  margin: 0;
}

body {
  color: colors.$text-color;
  background-color: colors.$right-pane-background;

  overflow-y: auto;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Inter', sans-serif;

  letter-spacing: normal;
  font-weight: 700;

  margin: 0;

  color: colors.$text-gray;
}

.font-title {
  font-family: 'Inter', sans-serif;
  letter-spacing: normal;
  font-weight: 700;
}

.mat-button-wrapper,
.mat-menu-item,
.nav-item {
  font-family: 'Inter', sans-serif;
  font-weight: bold;
}

label {
  margin-bottom: 0;
}

table, .table {
  margin-bottom: 0;
}

.page {
  $pageHeaderHeight: 80px;
  $pageSubHeaderHeight: 50px;

  padding: calc(#{$pageHeaderHeight} + 2rem) 2rem 2rem;

  height: 100%;

  display: flex;
  flex-direction: column;

  position: relative;

  background-color: colors.$right-pane-background;

  &.no-header {
    padding-top: 2rem;

    &.page-fluid {
      padding-top: 0;
    }
  }

  &.with-sub-header {
    padding-top: calc(#{$pageHeaderHeight} + #{$pageSubHeaderHeight} + 1rem);
  }

  &.page-fluid {
    padding-top: $pageHeaderHeight;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }

  .page-header {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: $pageHeaderHeight;

    padding: 1rem 2rem;

    color: colors.$text-color;

    background-image: linear-gradient(90deg, colors.$menu-background, colors.$menu-light-background);

    .mat-form-field {
      .mat-form-field-label {
        color: #fff;
      }

      .mat-input-element {
        caret-color: #fff;
      }

      .mat-form-field-underline {
        background-color: #fff;

        .mat-form-field-ripple {
          background-color: #fff;
        }
      }

      &.mat-focused {

      }
    }
  }

  .page-sub-header {
    height: $pageSubHeaderHeight;
    width: 100%;

    background-color: #fff;

    position: absolute;
    top: $pageHeaderHeight;
    left: 0;

    padding: 0;

    border-bottom: 1px solid rgb(74, 74, 74, 0.2);
  }
}

.cdk-overlay-pane {
  max-width: 95vw !important;
}

.horizontal-radio {
  .mat-radio-label {
    flex-direction: column;

    .mat-radio-label-content {
      padding-left: 0;
      padding-top: 10px;
    }
  }
}

.mat-expansion-panel {
  border: 1px solid colors.$border-color;
  border-radius: 6px;
}

.mat-nav-list {
  padding-top: 0 !important;

  &.left-menu {
    .mat-list-item {
      color: #fff;

      .mat-list-item-content {
        padding: 0 25px;
        min-width: 250px;
      }

      &.active {
        background-color: colors.$menu-active-background;
      }
    }
  }
}

.mat-form-field-appearance-outline {
  &.form-field-small {
    line-height: 34px;
    font-size: 14px;

    .mat-form-field-outline {
      background-color: #f0f1f2;
      border-radius: 5px;
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;
      margin: 0;
    }

    .mat-form-field-prefix {
      top: 0.35em
    }

    .mat-form-field-suffix {
      top: 0.35em
    }

    .mat-form-field-infix {
      padding: 0.2em 0;
      border-top: 0.2em solid transparent;
      border-bottom: 0 solid transparent;
    }
  }
}

$cardBoxPadding: 1rem;

.card-box {
  display: flex;
  flex-direction: column;

  width: 100%;

  padding: $cardBoxPadding;

  border-radius: 12px;

  background-color: #fff;

  box-shadow: 4px 5px 12px -1px #ABABAB;

  position: relative;

  overflow: hidden;

  .card-box-header {
    position: relative;

    background-color: colors.$gray-background-color;

    padding: 10px 16px;

    border-top-left-radius: 6px;
    border-top-right-radius: 6px;

    margin: #{-1 * $cardBoxPadding} #{-1 * $cardBoxPadding} $cardBoxPadding;
    border-bottom: 1px solid colors.$border-color;

    &.card-box-header-triangle {
      &::before {
        position: absolute;
        top: 13px;
        right: 100%;
        left: -16px;
        display: block;
        width: 0;
        height: 0;
        pointer-events: none;
        content: ' ';
        border-color: transparent;
        border-style: solid solid outset;
        border-width: 8px;
        border-right-color: colors.$border-color;
      }

      &::after {
        position: absolute;
        top: 13px;
        right: 100%;
        left: -16px;
        display: block;
        width: 0;
        height: 0;
        pointer-events: none;
        content: " ";
        border-color: transparent;
        border-style: solid solid outset;
        margin-top: 1px;
        margin-left: 2px;
        border-width: 7px;
        border-right-color: colors.$gray-background-color;
      }
    }

    & + .card-box-list-item {
      margin-top: #{-1 * $cardBoxPadding};
    }
  }

  .card-box-footer {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;

    margin: $cardBoxPadding #{-1 * $cardBoxPadding} #{-1 * $cardBoxPadding};
    border-top: 0;
  }

  .card-box-list-item {
    padding: 8px 16px;

    border-bottom: 1px solid colors.$border-color;

    margin: 0 #{-1 * $cardBoxPadding};

    &:hover {
      background-color: colors.$gray-darker-background-color;
    }

    &:first-child {
      margin-top: #{-1 * $cardBoxPadding};
    }

    &:last-child {
      border-bottom: 0;
      margin-bottom: #{-1 * $cardBoxPadding};
    }
  }

  &.card-box-color- {
    &blue {
      border-top: 10px solid colors.$blue-color;
    }

    &purple {
      border-top: 10px solid colors.$purple-color;
    }

    &orange {
      border-top: 10px solid colors.$orange-color;
    }

    &yellow {
      border-top: 10px solid colors.$yellow-color;
    }
  }
}

.selected-section {
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 4px;
  padding: 20px;

  .selected-section-header {
    color: rgba(0, 0, 0, .54);

    padding: 8px 8px 8px 16px;
    margin: -25px -16px 0 -16px;
  }
}

.border-rounded {
  border-radius: 6px;
}

.w-100-button {
  button {
    display: inline-block;
    width: 100%;
  }
}

.btn-big {
  padding: 0.5rem 1rem !important;

  font-size: 18px;
}

a.link-title {
  font-weight: 600;

  color: colors.$text-color;

  &:hover {
    text-decoration: none;
    color: colors.$link-color;
  }
}

.hover-link {
  cursor: pointer;

  &:hover {
    color: colors.$link-color;
  }
}

.indicator {
  height: 20px;
  width: 20px;
  border-radius: 100%;

  color: #fff;
  background-color: colors.$warn-color;

  font-size: 70%;

  display: inline-flex;

  flex-wrap: wrap;

  flex-direction: row;
  align-items: center;
  justify-content: center;

  user-select: none;

  text-decoration: none;

  &:hover, &:focus, &:active {
    text-decoration: none;
  }
}

.translate-new-line {
  white-space: pre-wrap;
}
