$main-yellow-color: #f0d642;
$main-green-color: #41bda6;
$main-light-green-color: #8dd6c9;
$main-dark-purple: #9972f3;
$main-dark-blue: #4761a3;
$main-red-purple: #b44f81;
$main-orange-color: #e06666;
$main-light-blue-color: #a4c2f4;
$main-light-purple-color: #b3a7d5;
$gray-color: #413d46;
$text-gray: #3b3b3c;

$primary-color: #9973f2;
$primary-text-color: #fff;
$secondary-color: #4CB963;
$secondary-text-color: #2d2829;
$third-color: #08866a;
$success-color: #6aa843;
$warn-color: #f44336;

$link-color: $secondary-text-color;

$title-color: #215068;
$text-soft-color: rgba(45, 40, 41, .5);

$text-color: #2d2829;
$border-color: #d0cece;
$highlighted-background-color: #efeded;
$hover-highlighted-background-color: #faf8f8;
$gray-background-color: #efefef;
$gray-darker-background-color: #f2f2f5;

$right-pane-background: #fafafa;

$menu-background: #f4e2cf;
$menu-light-background: #f9efe3;
$menu-active-background: #ecbeb4;
$menu-active-smooth-background: #fdccc3;

$blue-color: #1eaefe;
$purple-color: #a67ef6;
$orange-color: #fe7e74;
$yellow-color: #ffc344;

.text-normal {
  color: $text-color !important;
}

.text-gray {
  color: $text-gray;
}

.text-white {
  color: #fff;
}

.text-primary {
  color: $primary-color !important;
}

.text-secondary {
  color: $secondary-color !important;
}

.text-title {
  color: $title-color;
}

.text-soft {
  color: $text-soft-color;
}

.highlighted {
  background-color: $highlighted-background-color;
}

.left-menu-background {
  color: #fff;
  background-image: linear-gradient(180deg, $menu-background, $menu-light-background);

  .mat-list-item,
  .mat-nav-list a {
    color: #fff;
  }

  .mat-list-item {
    color: #fff;

    &.active {
      background-color: $menu-active-background;
    }
  }
}

.pane-background {
  background-color: $right-pane-background;
}

.bg-gray {
  background-color: #eee;
}

.bg-main-yellow {
  background-color: $main-yellow-color;
}

.fill-main-yellow {
  fill: $main-yellow-color;
}

.fill-main-green {
  fill: $main-green-color;
}

.bg-main-green {
  background-color: $main-green-color;

  color: #fff;
}

.fill-main-light-green {
  fill: $main-light-green-color;
}

.bg-main-light-green {
  background-color: $main-light-green-color;
}

.bg-main-dark-purple {
  color: #fff;
  background-color: $main-dark-purple;
}

.bg-main-red-purple {
  color: #fff;
  background-color: $main-red-purple;
}

.bg-main-dark-blue {
  color: #fff;
  background-color: $main-dark-blue;
}

.fill-main-light-blue {
  fill: $main-light-blue-color;
}

.bg-main-light-blue {
  background-color: $main-light-blue-color;
}

.fill-main-light-purple {
  fill: $main-light-purple-color;
}

.bg-main-light-purple {
  background-color: $main-light-purple-color;
}

.fill-main-orange {
  fill: $main-orange-color;
}

.bg-main-orange {
  background-color: $main-orange-color;

  color: #fff;
}

.bg-menu-active-background {
  background-color: $menu-active-background;
}

.bg-menu-active-smooth-background {
  background-color: $menu-active-smooth-background;
}

.bg-primary-faded {
  background-color: rgba($primary-color, 0.8);
}

.bg-secondary-color {
  background-color: $secondary-color;
}

.bg-blue {
  background-color: $blue-color !important;
}

.bg-purple {
  background-color: $purple-color !important;
}

.bg-orange {
  background-color: $orange-color !important;
}

.bg-yellow {
  background-color: $yellow-color !important;
}

.color-third {
  color: $third-color;
}

.color-success {
  color: $success-color;
}

.color-warn {
  color: $warn-color;
}

.white-border {
  border-color: #fff !important;
}
